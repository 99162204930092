import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["sliderWrapper", "sliderSvg", "sliderSvgPath", "sliderThumb", "sliderInput", "sliderValue", "dateTabButton", "timeTabButton", "daysOfWeekTabButton", "datetimePickerContent", "dateSliderInput", "dateSliderDayValue", "dateSliderDateValue", "datetimePickerInput", "finalDateTime", "triggerText", "timezone", "datepickerFooter", "timepickerFooter", "calendarDateInput"]
  readonly sliderWrapperTarget!: HTMLElement
  readonly sliderSvgTarget!: SVGSVGElement
  readonly sliderSvgPathTarget!: SVGGeometryElement
  readonly sliderThumbTarget!: HTMLElement
  readonly sliderInputTarget!: HTMLInputElement
  readonly hasDateSliderInputTarget!: Boolean
  readonly sliderValueTarget!: HTMLElement
  readonly dateTabButtonTarget!: HTMLElement
  readonly daysOfWeekTabButtonTarget!: HTMLElement
  readonly timeTabButtonTarget!: HTMLElement
  readonly datetimePickerContentTarget!: HTMLElement
  readonly dateSliderInputTarget!: HTMLInputElement
  readonly dateSliderDayValueTarget!: HTMLElement
  readonly dateSliderDateValueTarget!: HTMLElement
  readonly datetimePickerInputTarget!: HTMLInputElement
  readonly finalDateTimeTarget!: HTMLInputElement
  readonly triggerTextTarget!: HTMLElement
  readonly timezoneTarget!: HTMLInputElement
  readonly timepickerFooterTarget!: HTMLElement
  readonly datepickerFooterTarget!: HTMLElement
  readonly calendarDateInputTarget!: HTMLInputElement

  declare boundHandlePointerUp: EventListener | undefined

  connect() {
    this.sliderInputTarget.addEventListener('input', () => {
      const progress = this.sliderInputTarget.valueAsNumber / (this.getSliderMax() - this.getSliderMin());
      this.updatePosition(progress);
    });

    this.sliderWrapperTarget.addEventListener('selectstart', (event) => {
      event.preventDefault();
    })

    if (this.finalDateTimeTarget.value.length == 0) {
      this.updatePosition((this.sliderInputTarget.valueAsNumber - this.getSliderMin()) / (this.getSliderMax() - this.getSliderMin()));
    } else {
      this.initializeSliders()
    }
  }

  initializeTabs() {
    const tabButtons = this.datetimePickerContentTarget.querySelectorAll(".tab-button")
    for (const tabButton of tabButtons) {
      const tab = tabButton as HTMLElement
      if (tab.ariaSelected === "true") {
        if (tab.dataset.tab == "date") this.showDateTab()
        if (tab.dataset.tab == "time") this.showTimeTab()
        if (tab.dataset.tab == "days-of-week") this.showDaysOfWeekTab()
      }
    }
  }

  dateDiffInDays(a: Date, b: Date) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }

  initializeSliders() {
    const providedDate = new Date()
    const providedDateMillis = Date.parse(this.datetimePickerInputTarget.value)
    providedDate.setTime(providedDateMillis)

    if (this.hasDateSliderInputTarget) {
      // Change date slider. This involves finding out how many days from now the provided date is.
      // This number is the new value of the slider.
      const diffDays = Math.abs(this.dateDiffInDays(providedDate, new Date()))
      this.dateSliderInputTarget.value = `${diffDays}`
      this.setDateSlider(diffDays)
    }

    // Change the time slider. This involves calculating what percentage the provided time is
    // between 00:00 to 24:00. Convert the provided time to minutes by calculating its minutes since midnight
    // If the slider max is 100%, we can work out what % the progress is.
    let minutesSinceMidnight = (60 * providedDate.getHours()) + providedDate.getMinutes()
    let progress = minutesSinceMidnight / this.getSliderMax()
    this.updatePosition(progress)
  }

  padStart(target: string, targetLength: number, padString?: string) {
    targetLength = targetLength >> 0; //truncate if number or convert non-number to 0;
    padString = String((typeof padString != null ? padString : ' '));
    if (target.length > targetLength) {
      return String(target);
    }
    else {
      targetLength = targetLength - target.length;
      if (targetLength > padString.length) {
        padString += padString.repeat(targetLength / padString.length); //append to original to ensure we are longer than needed
      }
      return padString.slice(0, targetLength) + String(target);
    }
  };

  togglePicker(show: boolean, picker: HTMLElement) {
    show ? picker!.classList.remove("closed") : picker!.classList.add("closed")
  }

  showPicker(event: Event) {
    const picker = (event.target as HTMLElement).closest(".datetimepicker-wrapper")!.querySelector(".datetimepicker") as HTMLElement
    this.togglePicker(true, picker)

    this.initializeTabs()
  }

  hidePicker(event: Event) {
    const picker = (event.target as HTMLElement).closest(".datetimepicker") as HTMLElement
    this.togglePicker(false, picker)
    if (this.boundHandlePointerUp !== undefined) {
      window.removeEventListener('pointerup', this.boundHandlePointerUp);
    }
    const customEvent = new CustomEvent("datePicked"); 
    window.dispatchEvent(customEvent);
  }

  confirmPressed(event: Event) {
    if (this.datetimePickerInputTarget.value != null && this.datetimePickerInputTarget.value.length != 0) {
      const currentMillis = Date.parse(this.datetimePickerInputTarget.value)
      const finalDate = new Date()
      finalDate.setTime(currentMillis)

      const time = finalDate.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
      const weekday = finalDate.toLocaleDateString([], { weekday: "short" })
      const date = finalDate.toLocaleDateString([], { day: "2-digit", month: "short", year: "numeric" })

      switch (this.datetimePickerInputTarget.dataset.type) {
        case "datetime": {
          this.triggerTextTarget.textContent = `${time} ${weekday}, ${date}`
          this.finalDateTimeTarget.value = `${this.datetimePickerInputTarget.value} ${this.timezoneTarget.value}`
          break;
        }
        // TODO: allow any time or allow to set default time (currently sets 12:00 if nothing is touched)
        case "daysofweektime": {
          const form = this.daysOfWeekTabButtonTarget.closest("form")
          if (!form) return
          const daysOfWeek = new FormData(form).getAll('maintenance_setting[days_of_week][]')
          const daysOfWeekFormatted = daysOfWeek.map(index => document.querySelector(`label[for=maintenance_setting_days_of_week_${index}]`)?.textContent).join(", ")
          this.triggerTextTarget.textContent = daysOfWeek.length ? `${daysOfWeekFormatted} ${time}` : "Never"
          this.finalDateTimeTarget.value = `${date} ${time} ${this.timezoneTarget.value}`
          break;
        }
        case "date": {
          this.triggerTextTarget.textContent = `${date}`
          this.finalDateTimeTarget.value = `${date} ${this.timezoneTarget.value}`
          break;
        }
        case "time": {
          this.triggerTextTarget.textContent = `${time}`
          this.finalDateTimeTarget.value = `${time} ${this.timezoneTarget.value}`
          break;
        }
      }

    }

    this.hidePicker(event)
  }

  showDateTab() {
    if (this.getDateTab()) this.getDateTab().style.display = "flex"
    if (this.getTimeTab()) this.getTimeTab().style.display = "none"
    if (this.getDaysOfWeekTab()) this.getDaysOfWeekTab().style.display = "none"
    this.datepickerFooterTarget.classList.remove("hidden")
    this.timepickerFooterTarget.classList.add("hidden")
  }

  showDaysOfWeekTab() {
    if (this.getDateTab()) this.getDateTab().style.display = "none"
    if (this.getTimeTab()) this.getTimeTab().style.display = "none"
    if (this.getDaysOfWeekTab()) this.getDaysOfWeekTab().style.display = "flex"
    this.datepickerFooterTarget.classList.add("hidden")
    this.timepickerFooterTarget.classList.remove("hidden")
  }

  showTimeTab() {
    if (this.getDateTab()) this.getDateTab().style.display = "none"
    if (this.getTimeTab()) this.getTimeTab().style.display = "flex"
    if (this.getDaysOfWeekTab()) this.getDaysOfWeekTab().style.display = "none"
    this.datepickerFooterTarget.classList.add("hidden")
    this.timepickerFooterTarget.classList.remove("hidden")
    if (this.datetimePickerInputTarget.value.length == 0) this.resetTimePicker()
  }

  getDaysOfWeekTab() {
    return this.datetimePickerContentTarget.querySelector(".days-of-week-tab") as HTMLElement
  }

  getDateTab() {
    return this.datetimePickerContentTarget.querySelector(".datepicker-tab") as HTMLElement
  }

  getTimeTab() {
    return this.datetimePickerContentTarget.querySelector(".timepicker-tab") as HTMLElement
  }

  selectTab(event: Event) {
    const target = event.target as HTMLElement
    const tab = target.closest(".tab-button") as HTMLElement
    if (!tab) return

    const tabName = tab.dataset!.tab
    const tabs = tab.parentElement!.querySelectorAll(".tab-button")

    tabs.forEach((tab) => { tab.ariaSelected = "false" })

    if (tabName == "date") {
      this.dateTabButtonTarget.ariaSelected = "true"
      this.showDateTab()
    } else if (tabName == "days-of-week") {
      this.daysOfWeekTabButtonTarget.ariaSelected = "true"
      this.showDaysOfWeekTab()
    } else if (tabName == "time") {
      this.timeTabButtonTarget.ariaSelected = "true"
      this.showTimeTab()
    }
  }

  updateTime(timeInMinutes: number) {
    const minuteStep = +this.sliderInputTarget.dataset.minutestep!
    const roundedMinutes = Math.round(timeInMinutes / minuteStep) * minuteStep
    let hours = Math.floor(roundedMinutes / 60)
    const minutes = roundedMinutes % 60
    if (hours == 24 && minutes == 0) hours = 0
    const formattedHours = this.padStart(String(hours || 0), 2, "0")
    const formattedMinutes = this.padStart(String(minutes), 2, "0")
    const time = `${formattedHours}:${formattedMinutes}`
    this.sliderValueTarget.textContent = time
    this.setFinalDateTime(undefined, `${hours}:${formattedMinutes}`)
  }

  setFinalDateTime(date?: Date, time?: string) {
    let value = ""
    let fullDate = new Date()
    let currentHours
    let currentMinutes

    if (this.datetimePickerInputTarget.value != null && this.datetimePickerInputTarget.value.length != 0) {
      const currentMillis = Date.parse(this.datetimePickerInputTarget.value)
      fullDate.setTime(currentMillis)
      currentHours = fullDate.getHours()
      currentMinutes = fullDate.getMinutes()
    }


    if (date != undefined) {
      fullDate = date
      if (currentHours) fullDate.setHours(currentHours)
      if (currentMinutes) fullDate.setMinutes(currentMinutes)
    }

    if (time != undefined) {
      let hours = time.split(":")[0]
      let minutes = time.split(":")[1]
      fullDate.setHours(+hours)
      fullDate.setMinutes(+minutes)
      fullDate.setSeconds(0)
    }

    value = `${fullDate.toLocaleDateString([], { day: "2-digit", month: "short", year: "numeric", hour: "2-digit", minute: "2-digit" })}`

    this.datetimePickerInputTarget.value = value
  }

  setColor(progress: number) {
    const colorStops = [
      { r: 50, g: 0, b: 157 },  // #32009D
      { r: 255, g: 138, b: 0 },   // #FF8A00
      { r: 255, g: 199, b: 109 },  // #FFC700
      { r: 255, g: 199, b: 109 },   // #FFC700
      { r: 255, g: 138, b: 0 },     // #FF8A00
      { r: 50, g: 0, b: 157 }        // #32009D
    ];
    const numStops = colorStops.length;

    const index = (numStops - 1) * progress;
    const startIndex = Math.floor(index);
    const endIndex = Math.ceil(index);

    const startColor = colorStops[startIndex];
    const endColor = colorStops[endIndex];

    const percentage = index - startIndex;

    const [r, g, b] = [Math.round(startColor.r + (endColor.r - startColor.r) * percentage), Math.round(startColor.g + (endColor.g - startColor.g) * percentage), Math.round(startColor.b + (endColor.b - startColor.b) * percentage)];

    this.sliderThumbTarget.style.setProperty('--color', `rgb(${r} ${g} ${b})`);
  }

  updatePosition(progress: number) {
    if (progress < 0 || progress > 1) {
      console.log("Value provided to time slider was out of bounds.")
      return
    }
    const sliderPathLength = this.sliderSvgPathTarget.getTotalLength()
    const sliderMinValue = this.getSliderMin()
    const sliderMaxValue = this.getSliderMax()
    const point = this.sliderSvgPathTarget.getPointAtLength(progress * sliderPathLength);
    const svgRect = this.sliderSvgTarget.getBoundingClientRect();
    const scaleX = svgRect.width / this.sliderSvgTarget.viewBox.baseVal.width;
    const scaleY = svgRect.height / this.sliderSvgTarget.viewBox.baseVal.height;
    this.sliderThumbTarget.style.left = `${point.x * scaleX * 100 / svgRect.width}%`;
    this.sliderThumbTarget.style.top = `${point.y * scaleY * 100 / svgRect.height}%`;
    const value = Math.round((progress * (sliderMaxValue - sliderMinValue)) + sliderMinValue);
    this.sliderInputTarget.value = String(value);
    this.updateTime(value);
    this.setColor(progress);
  }

  reset() {
    if (this.hasDateSliderInputTarget) {
      this.dateSliderInputTarget.value = "0"
      this.setDateSlider(0)
    } else {
      const customEvent = new CustomEvent("reset"); 
      document.querySelector(".calendar-datepicker")?.dispatchEvent(customEvent);
    }
    this.finalDateTimeTarget.value = ""
    this.triggerTextTarget.textContent = this.triggerTextTarget.dataset.translation!
    this.resetTimePicker()
  }

  resetTimePicker() {
    this.updatePosition(0.5)
    this.datetimePickerInputTarget.value = ""
  }

  getSliderMin() {
    return +this.sliderInputTarget.min || 0
  }

  getSliderMax() {
    return +this.sliderInputTarget.max || 100
  }

  handlePointerMove(event: MouseEvent) {
    const context = (window as any).currentContext
    const changingSvg = document.querySelector(".--timepicker-changing") as SVGGeometryElement
    if (changingSvg) {
      const sliderWidth = changingSvg.getBoundingClientRect().width;
      const pointerX = event.clientX - changingSvg.getBoundingClientRect().left;
      const progress = Math.min(Math.max(pointerX / sliderWidth, 0), 1);
      context.updatePosition(progress);
    }
  }

  handlePointerDown(event: MouseEvent) {
    this.sliderSvgPathTarget.classList.add("--timepicker-changing")
    const sliderWidth = this.sliderSvgPathTarget.getBoundingClientRect().width;
    const pointerX = event.clientX - this.sliderSvgPathTarget.getBoundingClientRect().left;
    const progress = Math.min(Math.max(pointerX / sliderWidth, 0), 1);
    const target = event.target as HTMLElement
    const isThumb = target?.classList.contains('slider-thumb');
    if (!isThumb) this.updatePosition(progress);
    (window as any).currentContext = this
    window.addEventListener('pointermove', this.handlePointerMove);
    this.boundHandlePointerUp = this.handlePointerUp.bind(this)
    window.addEventListener('pointerup', this.boundHandlePointerUp);
  }

  handlePointerUp() {
    const changingSvg = document.querySelector(".--timepicker-changing") as SVGGeometryElement
    changingSvg?.classList.remove(".--timepicker-changing")
    window.removeEventListener('pointermove', this.handlePointerMove);
  }

  setDateSlider(value: number) {
    const slider = this.dateSliderInputTarget

    const date = new Date()
    date.setDate(date.getDate() + value + +slider.dataset.minmodifier!)

    this.dateSliderDayValueTarget.textContent = (date.toDateString() == new Date().toDateString()) ? "Today" : `${date.toLocaleDateString([], { weekday: "long" })},`;
    this.dateSliderDateValueTarget.textContent = date.toLocaleDateString([], { day: "numeric", month: "long" });

    const progress = (value / +slider.max) * 100;

    slider.style.background = `linear-gradient(to right, #0091FA ${progress}%, #ccc ${progress}%)`;

    this.setFinalDateTime(date)
  }

  calendarDateSelected() {
    this.setFinalDateTime(this.calendarDateInputTarget.value.length == 0 ? new Date() : new Date(this.calendarDateInputTarget.value))
  }

  dateSliderMoved() {
    this.setDateSlider(+this.dateSliderInputTarget.value)
  }
}
