import { Controller } from "@hotwired/stimulus"
import * as am5 from "@amcharts/amcharts5"
import * as am5xy from "@amcharts/amcharts5/xy"
import { railsToJsTimezone } from "../rails_to_js_timezone"

export default class extends Controller {
  static values = {
    type: { type: String, default: "stacked_column" }
  }
  root!: am5.Root
  chart!: am5xy.XYChart
  xAxis!: am5xy.DateAxis<am5xy.AxisRenderer>
  yAxis!: am5xy.ValueAxis<am5xy.AxisRenderer>
  color!: am5.Color

  readonly typeValue!: string
  readonly chartTypes = {
      STACKED_COLUMN: "stacked_column",
      LINE: "line"
  }

  connect() {
    //  Determine whether dark mode is active
    const content = document.querySelector("html") as HTMLElement
    const dark = content?.classList.contains("dark")

    const existingController = content.dataset.controller || ""
    content.dataset.controller = `${existingController} class-observer`
    content.dataset.classObserverTarget = "observee"
    content.dataset.eventTarget = "#chart"

    //  Set colours that vary between light/dark mode
    this.color = dark ? am5.color(0xffffff) : am5.color(0x000000)
    const rootSettings = this.typeValue === this.chartTypes.LINE ? {
          tooltipContainerBounds: {
          top: 50,
          right: 100,
          bottom: 50,
          left: 100
        }
      } : {}

     //  Create root element + chart
     this.root = am5.Root.new("chart", rootSettings)
     this.root._logo?.dispose()
     this.chart = this.root.container.children.push(am5xy.XYChart.new(this.root, {}))

    //  y-Axis
    this.yAxis = this.chart.yAxes.push(
      am5xy.ValueAxis.new(this.root, {
      min: 0,
      renderer: am5xy.AxisRendererY.new(this.root, {})
      })
    )

    //  x-Axis configured with varying baseInterval depending on selected period
    const timeSpec = JSON.parse((this.element as HTMLElement).dataset.timespec || "{}")
    this.xAxis = this.chart.xAxes.push(
      am5xy.DateAxis.new(this.root, {
        renderer: am5xy.AxisRendererX.new(this.root, { minGridDistance: 50 }),
        baseInterval: timeSpec,
        tooltip: am5.Tooltip.new(this.root, {
            getFillFromSprite: false
        })
      })
    )
    this.createChart()
  }

  classlistChanged() {
    const dark = document.querySelector("html")!.classList.contains("dark")
    this.color = dark ? am5.color(0xffffff) : am5.color(0x000000)
    this.createChart()
  }

  createChart() {
    //  Create chart cursor
    this.chart.set("cursor", am5xy.XYCursor.new(this.root, {}))

    //  Configure cursor styling
    const cursor = this.chart.get("cursor")
    cursor?.lineX.setAll({
      stroke: this.color,
      strokeOpacity: 0.4,
      strokeWidth: 1,
      strokeDasharray: [2, 2]
    });

    cursor?.lineY.setAll({
      stroke: this.color,
      strokeOpacity: 0.4,
      strokeWidth: 1,
      strokeDasharray: [2, 2],
      visible: false
    });
      
    const tooltipX = this.xAxis.get("tooltip") as am5.Tooltip
    tooltipX?.get("background")?.setAll({
      fill: this.color,
      fillOpacity: 1,
      strokeOpacity: 0
    })  

    //  Inject the chart data
    const data = JSON.parse((this.element as HTMLElement).dataset.dataset || "[]")
    if (this.typeValue === this.chartTypes.STACKED_COLUMN) {
      this.configureXYStyling({ strokeOpacity: 0 })
      this.columnSeries(data)
    } else if (this.typeValue === this.chartTypes.LINE) {
      this.configureXYStyling({
        stroke: this.color,
        strokeOpacity: 0.1,
        strokeWidth: 1
      })
      this.xySeries(data, tooltipX)
    }
  }

  configureXYStyling(xRendererGridOptions={}){
    //  Configure x-Axis styling (maybe only works in dark mode?)
    const xRenderer = this.xAxis.get("renderer")
    xRenderer.grid.template.setAll(xRendererGridOptions)
    xRenderer.labels.template.setAll({
      fill: this.color,
      fontSize: "14px",
      fontFamily: "Inter",
      fontWeight: "500",
      location: 0
    })

    //  Configure y-Axis styling
    const yRenderer = this.yAxis.get("renderer")
    yRenderer.grid.template.setAll({
      stroke: this.color,
      strokeWidth: 1,
      strokeOpacity: 0.1
    })
    yRenderer.labels.template.setAll({
      fill: this.color,
      fontSize: "12px",
      fontFamily: "Inter",
      textAlign: "center",
    })
  }

  columnSeries(data: any) {
    this.root.numberFormatter.set("numberFormat", "#")
    this.xAxis.get("dateFormats")!["hour"] = "ha"
    const timezone = (this.element as HTMLElement).dataset.timezone ?? null
    if (timezone) this.root.timezone = am5.Timezone.new(railsToJsTimezone[timezone])

    const seriesSettings = JSON.parse(
        (this.element as HTMLElement).dataset.seriesspec || "[]"
      )
  
    seriesSettings.forEach(({ key, color }: { key: string; color: string }) => {
    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    const series = this.chart.series.push(
      am5xy.ColumnSeries.new(this.root, {
      stacked: true,
      xAxis: this.xAxis,
      yAxis: this.yAxis,
      valueYField: key,
      valueXField: "x",
      tooltip: am5.Tooltip.new(this.root, {
        pointerOrientation: "horizontal",
        labelText: "{valueY}"
      }),
      stroke: am5.color(color),
      fill: am5.color(color)
      })
    )

    series.columns.template.setAll({
      width: am5.percent(67),
      cornerRadiusTL: 2, // TODO: only for the top of the stack
      cornerRadiusTR: 2
    })

    series.data.setAll(data)
    })
  }

  xySeries(data: any, tooltipX: am5.Tooltip) {
    this.root.numberFormatter.set("numberFormat", "#.000");

    const tooltip = am5.Tooltip.new(this.root, {
      pointerOrientation: "down",
      labelText: "{valueY}"
    })

    this.setTooltipPosition(tooltip, -15)

    const series = this.chart.series.push(am5xy.SmoothedXYLineSeries.new(this.root, {
        minBulletDistance: 10,
        connect: false,
        tension: 0.75,
        xAxis: this.xAxis,
        yAxis: this.yAxis,
        valueYField: "y",
        valueXField: "x",
        tooltip: tooltip,
        stroke: am5.color("#b410b8"),
        fill: am5.color("#b410b8")
      }))

    series.data.setAll(data)

    this.setTooltipPosition(tooltipX)

    const gradient = am5.LinearGradient.new(this.root, {
      stops: [{
        color: am5.color("#cc51d7"),
        opacity: 0.2
      }, {
        color: am5.color("#204b73"),
        opacity: 0
      }]
    })

    series.strokes.template.setAll({
      strokeWidth: 2
    })
    series.fills.template.setAll({    
      fillGradient: gradient,
      visible: true
    })
  }

  setTooltipPosition(tooltip: am5.Tooltip, yPosition: number = 0) {
    tooltip.adapters.add("bounds", (bounds, target) => {
      const pointTo = target.get("pointTo")
      if (bounds && pointTo) {
        bounds.left = pointTo.x - (target.width() / 2)
        bounds.right = pointTo.x + (target.width() / 2)
      }
      return bounds
    })

    if (yPosition != 0) {
      tooltip.adapters.add("y", () => {
        return yPosition
      })
    }
  }
}